/* productsPopup */
.productsPopup {
  position: fixed;
  top: 58px;
  left: 58px;
  right: 58px;
  bottom: 58px;
  background: #161c24;
  z-index: 1001;
}
