/* iconButton */
.iconButton {
  border: 1px solid #353948;
  margin-bottom: 3px;
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: #212b36;
  font-size: 14px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: default;
  color: #fff;
  padding: 0 10px;
}

.iconButton:hover {
  background: #161c24;
  border: 1px solid #c4cdd5;
}
