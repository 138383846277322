/* mainVarientCard */
.mainVarientCard {
  border: 1px solid #353948;
  height: 180px;
  border-radius: 20px;
}
/* mainVarient-name */
.mainVarient-name {
  text-align: center;
  height: 40px;
  line-height: 40px;
}
/* varient-image */
.varient-image {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.varient-image img {
  width: 100%;
  height: 100%;
}
/* mainVarient-color */
.mainVarient-color {
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.mainVarient-color .varient-color {
  background: #000;
  width: 30px;
  height: 20px;
}
