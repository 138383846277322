.legalTextsPopup-popup {
  position: fixed;
  top: 58px;
  left: 58px;
  right: calc(15vw + 1px);
  bottom: 58px;
  background: #161c24;
  z-index: 1;
  overflow-y: scroll;
  color: #fff !important;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
}

.legalTextsPopup-popup .desc-left {
  width: 48%;
}
.legalTextsPopup-popup .desc-right {
  width: 48%;
}
.legalTextsPopup-popup ol {
  margin-left: 20px;
}
.legalTextsPopup-popup table {
  border: 1px solid #353948;
  border-collapse: collapse;
}
.legalTextsPopup-popup table td {
  border: 1px solid #353948;
  padding: 10px;
}
/* close-open-legle-text */
.close-open-legle-text {
  border: 1px solid #353948;
  padding: 10px;
  height: 80%;
  border-radius: 20px;
}
