.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.df {
  display: flex;
}
.fw-300 {
  font-weight: 300;
}
.cl-black {
  color: #000;
}

.outline-border-none {
  outline: none;
  border: none;
}
.cup {
  cursor: pointer;
}
.jcc {
  justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.aic {
  align-items: center;
}
.lph {
  height: calc(100vh - 58px - 78px);
}
.ofys {
  overflow-y: scroll;
}
.fz-22 {
  font-size: 22px;
}
.fz-16 {
  font-size: 16px;
}
.tal {
  text-align: left;
}
.pl-10 {
  padding-left: 10px;
}
a {
  text-decoration: none;
  color: blue;
}

.color-white {
  color: #fff;
}

.over-flowy-scroll {
  overflow-y: scroll;
}

.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}

.tac {
  text-align: center;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}

.w5p {
  width: 5%;
}
.w10p {
  width: 10%;
}
.w15p {
  width: 15%;
}
.w20p {
  width: 20%;
}
.w25p {
  width: 25%;
}
.w30p {
  width: 30%;
}
.w35p {
  width: 35%;
}
.w40p {
  width: 40%;
}
.w45p {
  width: 45%;
}
.w50p {
  width: 50%;
}
.w55p {
  width: 55%;
}
.w60p {
  width: 60%;
}
.w65p {
  width: 65%;
}
.w70p {
  width: 70%;
}
.w75p {
  width: 75%;
}
.w80p {
  width: 80%;
}
.w85p {
  width: 85%;
}
.w90p {
  width: 90%;
}
.w95p {
  width: 95%;
}
.w100p {
  width: 100%;
}
.cud {
  cursor: default;
}

a {
  color: #1ecaf2;
}

a:hover {
  color: #1ecaf2;
  text-decoration: underline;
}
