/* studio-dropdown */
.studio-dropdown {
  margin-top: 10px;
  border: 1px solid #353948;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  line-height: 50px;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.studio-dropdown h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  text-align: left;
  cursor: default;
  margin-left: 10px;
}
.studio-dropdown i {
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 0;
  right: 5px;
}
.studio-dropdown ul {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 60px;
  border: 1px solid #353948;
  line-height: 40px;
  width: 100%;
  padding: 0;
  max-height: 300px;
  border-radius: 10px;
  z-index: 1;
}
.studio-dropdown ul li {
  font-size: 18px;
  color: #ccc;
  font-weight: 300;
  text-align: center;
  border-bottom: 1px solid #353948;
  line-height: 40px;
  margin-left: 10px;
  background: #161c24;
  cursor: default;
}
.studio-dropdown ul li:hover {
  font-size: 20px;
  color: #fff;
}
