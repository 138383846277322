/* productSearch-input */
.productSearch-input {
  border: 1px solid #1ccaff;
  width: 230px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: inline-block;
  position: relative;
}
.productSearch-input input {
  outline: none;
  background-color: transparent;
  padding: 10px;
  font-size: 14px;
  width: 180px;
  color: #fff;
  border: none;
}
.productSearch-input i {
  position: absolute;
  background-color: transparent;
  font-size: 20px;
  color: #fff;
  right: 10px;
  top: 8px;
}
