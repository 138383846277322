/* productDetail */
.productDetail {
  position: relative;
  height: 100%;
}
/* productDetaiolLower */
.productDetaiolLower {
  position: absolute;
  top: 290px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border: 1px solid #353948;
  color: #fff;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.productDetaiolLower .desc-left {
  width: 48%;
}
.productDetaiolLower .desc-right {
  width: 48%;
}
.productDetaiolLower ol {
  margin-left: 20px;
}
.productDetaiolLower table {
  border: 1px solid #353948;
  border-collapse: collapse;
}
.productDetaiolLower table td {
  border: 1px solid #353948;
  padding: 10px;
}
