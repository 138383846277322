/* studio-dropdown */
.studio-dropdown1 {
  border: 1px solid #1ccaff;
  width: 230px;
  height: 37px;
  border-radius: 3px;
  line-height: 37px;
  display: inline-block;
  position: relative;
}
.studio-dropdown1 h4 {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: left;
  cursor: default;
  margin-left: 10px;
}
.studio-dropdown1 i {
  font-size: 30px;
  color: #1ccaff;
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 0;
  right: 5px;
  z-index: -10;
}
.studio-dropdown1 ul {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  position: absolute;
  top: 45px;
  border: 1px solid #1ccaff;
  line-height: 40px;
  width: 100%;
  padding: 0;
  max-height: 300px;
  border-radius: 3px;
  z-index: 1;
  overflow-y: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.studio-dropdown1 ul li {
  font-size: 18px;
  color: #ccc;
  font-weight: 300;
  text-align: center;
  border-bottom: 1px solid #353948;
  line-height: 40px;
  background: #161c24;
  cursor: default;
  margin: 0;
}
.studio-dropdown1 ul li:hover {
  font-size: 20px;
  color: #fff;
}
