/* prodDetailColors */
.prodDetailColors {
  width: 100%;
}
.prodDetail_colors {
  margin: 0;
  margin-left: 2px;
}
.prodDetail_color {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 1px solid transparent;
}
.prodDetail_color:hover {
  border: 1px solid #fff;
}
