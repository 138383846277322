.clarification-popup {
  position: fixed;
  top: 58px;
  left: 58px;
  right: calc(15vw + 1px);
  bottom: 58px;
  background: #161c24;
  z-index: 90;
  overflow-y: scroll;
  color: #fff !important;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.clarification-popup .desc-left {
  width: 48%;
}
.clarification-popup .desc-right {
  width: 48%;
}
.clarification-popup ol {
  margin-left: 20px;
}
.clarification-popup table {
  border: 1px solid #353948;
  border-collapse: collapse;
}
.clarification-popup table td {
  border: 1px solid #353948;
  padding: 10px;
}
