/* statusInOrderDetail */
.statusInOrderDetail {
  margin-top: 10px;
  border: none;
  padding: 10px 0;
  margin-right: 10px;
}
/* statusBtns */
.statusBtns {
  margin-top: 10px;
}
