/* statusInOrderDetail */
.statusInOrderDetail {
  margin-top: 10px;
  border: none;
  padding: 10px;
  margin-right: 10px;
  border: 3px solid #ffc107;
}
/* statusBtns */
.statusBtns {
  margin-top: 10px;
}
