.checkout {
  width: 100%;
}

.checkout-heading {
  color: #fff;
  text-align: center;
  margin-top: 20px;
  font-weight: 300;
}

.empty-cart-msg {
  text-align: center;
  font-weight: 300;
  color: #fff;
}

.preview-btn {
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  border: none;
}
