.orderDetail {
  margin: 20px;
  height: calc(100vh - 70px - 41px);
  overflow-y: scroll;
}

.showaddress {
  margin-top: 10px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #fff;
  /* border-left: 3px solid #ff6600; */
  /* border-bottom: 3px solid #ff6600; */
}

.dtal-prod {
  margin-top: 10px;
  border: none;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #fff;
}

.dtal-prod p {
  font-size: 22px;
  margin-top: 5px;
}

.dtal-prod .adlable {
  color: #00a86b;
}
.showaddress p {
  font-size: 22px;
  margin-top: 5px;
}

.adlable {
  color: #00a86b;
}
