/* singleStudioOrder */
.singleStudioOrder {
  padding: 0 20px;
}

.orderSumry {
  background: #212b36;
  margin-left: 30px;
  width: calc(25% - 50px);
  margin-top: 10px;
  height: auto;
  padding: 10px;
  border-radius: 10px;
}

/* singleOrder-studio-text */
.singleOrder-studio-text {
  color: #fff;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 300;
}
.singleOrder-studio-text span {
  color: #c0c5ce;
  margin-right: 5px;
  font-weight: 500;
}
/* orderbutton */
.orderbutton {
  width: 100%;
  height: 50px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: #171c24;
  cursor: pointer;
}

/* buyer-addresses */
.buyer-addresses{
  border: 1px solid #fff;  
  margin-top: 10px;
  padding: 10px;
}
.buyer-addresses p{
  color: #fff;
  margin-bottom: 5px;
}
.buyer-addresses p span{
  color: #c0c5ce;
}