/* image-wrap */
.image-wrap {
  position: relative;
}
.image-wrap img {
  width: 100%;
}
.warning-upload-img {
  position: absolute;
  top: 50%;
  left: 20%;
  color: red;
  text-align: center;
}
