/* product-item */
.product-item {
  width: 200px;
  height: 250px;
  border: 1px solid #353948;
  margin-bottom: 20px;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  border-radius: 5px;
}
.product-item .prod-image {
  height: 200px;
  border-bottom: 1px solid #353948;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.product-item .prod-image img {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.product-item .prod-text-box {
  padding: 10px;
  color: #fff;
  position: relative;
  text-align: left;
}
.product-item .prod-text-box h4 {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
}
.product-item .prod-text-box h5 {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
}
.product-item .prod-text-box h5 span {
  font-size: 14px;
  font-weight: 500;
  color: #74caff;
  margin-right: 5px;
}
.product-item .prod-text-box i {
  position: absolute;
  top: 25px;
  right: 10px;
}
/* product-overlay */
.product-overlay {
  position: absolute;
  left: 0;
  /* top: 150px; */
  right: 0;
  bottom: 50px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  text-align: left;  
}
