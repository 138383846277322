.p6-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #353948;
}
.p6-header-left {
  display: flex;
  align-items: center;
}
.backarrow {
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
  background-color: #161c24;
}

.backarrow:hover {
  background-color: #212b36;
}

.minusicon {
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
  background: none;
}

.minusicon:hover {
  background-color: #353948;
}
