/* productsList */
.productsList {
  padding: 20px;
}

/* dummy-wrapper */
.dummy-btn {
  border: 1px solid #353948;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  position: relative;
}
/* product-popup-close-icon */
.product-popup-close-icon {
  color: #c4cdd5;
  font-size: 50px;
  border: 1px solid #353948;
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: default;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #212b36;
}
.product-popup-close-icon:hover {
  background: #161c24;
  border: 1px solid #c4cdd5;
  color: #fff;
}

.dummy-btn:hover {
  background: #161c24;
  border: 1px solid #c4cdd5;
}

.dummy-btn span {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
}

/* popup-body */
.popup-body {
  margin-top: 10px;
  height: calc(100vh - 220px);
  display: flex;
}

/* products-area */
.products-area {
  border: 1px solid #353948;
  width: calc(100% - 230px);
  margin-left: 10px;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0;
  padding-left: 0;
  text-align: center;
  position: relative;
}
