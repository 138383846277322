/* orderInformation */
.orderInformation {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
/* orderInformation-left */
.orderInformation-left {
  width: 45%;
  padding: 10px;
  border: 1px solid #fff;
}
.orderInformation-left h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.orderInformation-left p {
  font-size: 18px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.orderInformation-left p span {
  width: 50%;
}
/* orderInformation-right */
.orderInformation-right {
  width: 45%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  border: 1px solid #fff;
}
/* billingAddress */
.orderInformation-right .billingAddress {
  width: 45%;
}
.orderInformation-right .billingAddress h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.orderInformation-right .billingAddress p {
  display: flex;
  justify-content: space-between;
}
/* shipingAddress */
.orderInformation-right .shipingAddress {
  width: 45%;
}
.orderInformation-right .shipingAddress h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.orderInformation-right .shipingAddress p {
  display: flex;
  justify-content: space-between;
}
