/* .midlearea-main-wrapper { */
/* text-align: center;
  background-color: #242631;
  color: white;
  border-right: 1px solid #353948;
  width: calc(100vw - 40vw - 58px);
  height: calc(100vh - 120px); */
/* overflow-x: scroll;
  overflow-y: scroll; */
/* padding-left: 100px;
  padding-right: 100px; */
/* } */
.midlearea {
  color: white;
  height: calc(100vh - 112px - 35px);
  padding: 10px;
  padding-bottom: 20px;
  margin: 0 auto;
}
/* canvas-table */
.canvas-table {
  width: 100%;
  margin: auto;
}
.canvas-table tr {
  display: flex;
  justify-content: center;
}
.canvas-table tr td {
  display: inline-block;
  text-align: center;
}
