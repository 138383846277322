.draw-modes {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}
