/* ordersDashboard */
.ordersDashboard {
  margin: 20px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}
/* totalRevenue-box */
.totalRevenue-box {
  width: 250px;
  height: 110px;
  border-radius: 20px;
  background-color: #212b36;
  padding: 20px;
  border: 1px solid #353948;
}
.totalRevenue-box .title {
  font-size: 20px;
  text-transform: uppercase;
}
.totalRevenue-box .value {
  font-size: 50px;
  text-transform: uppercase;
}
