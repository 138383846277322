.footerPanel-main-wrapper {
  /* background-color: #242631; */
  border-top: 1px solid #353948;
  color: white;
  height: 56px;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
}

.left-bottom-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  height: 40px;
  width: 120px;
}
.leftIcon {
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
  background-color: #161c24;
}
.leftIcon:hover {
  background-color: #3c3c41;
}
.center-bottom-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 200px;
}
.center-icon {
  cursor: pointer;
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
  background-color: #2e313f;
}
.center-icon:hover {
  background-color: #3c3c41;
}
.center-icon-two {
  font-size: 35px;
  color: white;
}
.center-icon-two:hover {
  color: #484d61;
}
.right-bottom-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 140px;
  margin-right: 20px;
}
.right-icon {
  font-size: 16px;
  padding: 8px;
  border-radius: 5px;
  background-color: #2e313f;
  color: white;
}
.right-icon:hover {
  background-color: #484d61;
}

.czoom {
  width: 250px;
  text-align: center;
}

.bd-outline-none {
  border: none;
  outline: none;
}
.cup {
  cursor: pointer;
}
