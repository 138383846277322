.dashboard {
  width: 100%;
  color: #fff;
}

.dashboard-header {
  border-bottom: 1px solid #353948;
}

.dashboard-header h1 {
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.dashboard-body {
  display: flex;
}

.dashboard-sidebar {
  width: 20%;
  border-right: 1px solid #353948;
  height: calc(100vh - 71px - 20px);
}
.dashboard-sidebar {
  width: 20%;
  border-right: 1px solid #353948;
  height: calc(100vh - 71px);
}

.dashboard-content-area {
  width: 100%;
}

.content-wrapper {
  display: flex;
}

.content-wrapper .contarea-right {
  margin-left: 20px;
}

.submit-button-wrapper {
  margin: 20px;
}

/* userManagement start */
.userManagement {
  margin: 20px;
}

.userManagement h1 {
  font-size: 22px;
  font-weight: 300;
}
/* userManagement end */

/* orders start */
.orders {
  margin: 20px;
}
.orders h1 {
  font-size: 22px;
  font-weight: 300;
}
.filters-wrapper {
  margin-top: 10px;
}
.applyFilter-button-wrapper {
  margin: 20px;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 0;
}
/* orders end */

/* pictures start */
.pictures {
  margin: 20px;
  height: calc(100vh - 71px - 40px);
  overflow-y: scroll;
}
/* pictures end */

.claim-button {
  background-color: #ffc107;
  color: #161c24;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.header-icon-btn {
  border-radius: 5px;
  background: #161c24;
  color: #f9fafb;
  border: none;
  height: 35px;
  width: 40px;
  font-size: 18px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.header-icon-btn:hover {
  border-radius: 5px;
  background: #212b36;
  color: #f9fafb;
  border: none;
  height: 35px;
  width: 40px;
  font-size: 18px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.header-icon-btn {
  background-color: #161c24;
}

/* seller-functionalities */
.seller-functionalities {
  border: 1px solid #353948;
  width: 300px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: block;
  padding: 20px;
}
