/* iconBox */
.iconBox {
  width: 80px;
  height: 80px;
  background: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.iconBox i {
  font-size: 50px;
}
