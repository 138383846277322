.panel6-main-wrapper {
  min-height: calc(100vh - 57px - 20px);
  width: 15vw !important;
  /* background-color: #242631; */
  color: white;
  border-right: 1px solid #353948;
}

.lp3-options {
  padding-left: 5px;
  /* height: 500px;
  overflow-y: scroll; */
}
