/* canvasButton */
.canvasButton {
  text-align: left;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #353948;
  padding: 10px 5px;
  align-items: center;
}

.canvasButton p {
  font-size: 12px;
  cursor: default;
  width: 50%;
  overflow: hidden;
}

.groupIcons-box {
  display: flex;
  align-items: center;
  border: 1px solid #353948;
  padding: 5px;
}
.groupIcons-box .groupIcon {
  margin-left: 5px;
  font-size: 20px;
  padding-top: 3px;
}
