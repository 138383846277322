.leftPanel1-main-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 56px;
  width: 56px;
  z-index: 10;
  height: calc(100vh - 56px);
  border-right: 1px solid #353948;
  transition: width 0.1s ease-out, box-shadow 0.1s ease-out;
  color: white;
}

.leftPanel1-main-wrapper .my-options-list {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 10px;
}

.leftPanel1-main-wrapper .my-options-list li {
  margin-bottom: 10px;
  margin-top: 10px;
}

.leftPanel1-btn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 10px;
  height: 30px;
  width: 30px;
}

.leftPanel1-btn i {
  font-size: 18px;
  color: #f9fafb;
  margin-right: 5px;
}

.leftPanel1-btn-text {
  font-size: 12px;
  color: white;
}

.leftPanel1-btn:hover {
  background: #212b36;
}
