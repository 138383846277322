/* productDetailUpper */
.productDetailUpper {
  display: flex;
}
/* mainImage */
.mainImage {
  width: 250px;
  height: 250px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
/* detailInfo */
.detailInfo {
  width: calc(100% - 200px - 20px - 40px);
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}
/* mainTitle */
.mainTitle {
  border: 1px solid #353948;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
}
/* detaiProdBack */
.detaiProdBack {
  position: absolute;
  left: 10px;
  font-size: 22px;
  cursor: default;
}
.detaiProdBack:hover {
  font-size: 30px;
  background: transparent;
}
/* colorSizeWrapper */
.colorSizeWrapper {
  display: flex;
}
/* prodDetailSize */
.prodDetailSize {
  margin-left: 10px;
  margin-top: 5px;
  width: 50%;
}
.sizeTitle {
  font-size: 22px;
  color: #fff;
  font-weight: 300;
}
.sizes {
  height: 60px;
  margin-top: 5px;
  border: 1px solid #353948;
  border-radius: 10px;
  padding-top: 10px;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.size {
  border: 1px solid #353948;
  background: #ffffff;
  display: inline-block;
  min-width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 300;
  line-height: 40px;
  color: #161c24;
  padding: 0 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
/* prodDetailPriceSelect */
.prodDetailPriceSelect {
  border: 1px solid #353948;
  margin-top: 10px;
  height: 79px;
  border-radius: 10px;
  display: flex;
}
.prodDetailPriceSelect .priceBox {
  width: 50%;
  padding: 20px;
}
.prodDetailPriceSelect .priceBox h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}
.prodDetailPriceSelect .priceBox h5 {
  color: #74caff;
  font-size: 28px;
  font-weight: 300;
}
.prodDetailPriceSelect .priceBox h5 span {
  color: #919eab;
  font-size: 18px;
  font-weight: 300;
}
/* selectButtonBox */
.selectButtonBox {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectButtonBox .productSelectButton {
  width: 50%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: default;
  border: 1px solid #353948;
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 12, 24, 1) 100%
  );
}
.selectButtonBox .productSelectButton span {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
}
