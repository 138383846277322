.leftPanel2-main-wrapper {
  text-align: center;
  min-height: calc(100vh - 57px);
  width: 15vw !important;
  /* background-color: #242631; */
  color: white;
  border-right: 1px solid #353948;
  margin-right: 10px;
}

.image-grid-container {
  margin-top: 10px;
}

.gridImg {
  cursor: pointer;
}

.edit-options {
  padding: 10px;
  height: calc(100vh - 58px - 80px);
  /* overflow-y: scroll; */
}

ul::-webkit-scrollbar-thumb {
  background-color: #04297a;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

ul::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
ul {
  overflow-y: scroll;
}
