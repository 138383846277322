.panel3Header-main-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #353948;
      padding: 10px 20px;
}

.panel3Header-main-wrapper h4{
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.info-logo-wrapper{
      outline: none;
      font-size: 18px;
      /* background-color: #343641; */
      background: none;
      color: white;      
      border-radius: 5px;
      border: none;
      padding: 8px;
}
.info-logo-wrapper:hover {
      background-color: #2e313f;
}
.body-main-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid blue;
}
.p3btn{
      border: 1px solid red;
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #353948;
      width: 200px;
      
}