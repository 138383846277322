.three-d-canvas {
  position: fixed;
  top: 58px;
  right: 15%;
  /* width: 401px; */
  /* height: 801px; */
  background-color: #fff;
  border: 1px solid #242631;
  z-index: 1;
}

.threeDRefresh {
  position: absolute;
  left: 0;
  color: #1ccaff;
  font-size: 30px;
  z-index: 100;
  cursor: pointer;
}
