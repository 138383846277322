* {
  margin: 0;
  padding: 0;
}
.header-main-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  height: 56px;
  padding: 0 1rem;
  /* background: #242631; */
  border-bottom: 1px solid #353948;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
}

.left .logo-icon {
  font-size: 18px;
}

.left button {
  border: none;
  height: 35px;
  margin-right: 10px;
  border-radius: 9px;
  background-color: #0e77b7;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
}

.left button:hover {
  background-color: #1890ff;
}

.center button {
  border: none;
  height: 35px;
  margin-left: 10px;
  border-radius: 9px;
  background-color: #2e313f;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
}
.center button:hover {
  background-color: #353948;
}

.right {
  display: flex;
  align-items: center;
  position: relative;
}

.right .header-upgrage-btn {
  background-color: #8a9dff;
  outline: none;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  height: 35px;
  cursor: pointer;
  padding: 10px;
}
.right .header-upgrage-btn:hover {
  background-color: #5c75ee;
}
.right .header-icon-btn {
  border-radius: 5px;
  background: #161c24;
  color: #f9fafb;
  border: none;
  height: 35px;
  width: 40px;
  font-size: 18px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.right .header-icon-btn:hover {
  border-radius: 5px;
  background: #212b36;
  color: #f9fafb;
  border: none;
  height: 35px;
  width: 40px;
  font-size: 18px;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.right .header-icon-btn {
  background-color: #161c24;
}

/* .login-dropdown {
  position: absolute;
  right: 0px;
  top: 40px;
  background-color: #fff;
  width: 150px;
  text-align: left;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */

.login-dropdown {
  list-style: none;
  padding: 0;
  margin: 0;
}

.login-dropdown li {
  border-bottom: 1px solid #353948;
  padding: 10px;
  cursor: pointer;
}

.login-dropdown li a {
  color: #fff;
}

.logout-li {
  border: none;
  color: #ff4842;
}

.cart-q {
  position: absolute;
  color: #ff4842;
  top: -5px;
  right: -5px;
  font-weight: bold;
}

.h-selected-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #fff;
}

.h-selected-size {
  color: #ff6600;
}
.logo-img {
  width: 110px;
}
.login-dropdown-wrap {
  position: fixed;
  /* background-color: #c4cdd5; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
/* productsPopupWrapper */
.productsPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  backdrop-filter: blur(2px);
}
.underlineOnHover:hover{
  text-decoration: underline
}