/* orderDetailTopBoxes */
.orderDetailTopBoxes {
  margin-top: 20px;
  display: flex;
}
/* dateBox */
.dateBox {
  display: flex;
  width: 25%;
}
.dateBox .textbox {
  padding: 10px;
}
.dateBox .textbox p {
  margin-top: 10px;
  font-size: 22px;
}
