/* prodDetailColors */
.prodDetailColors {
  margin-top: 5px;
  width: 50%;
}
.colorTitle {
  font-size: 22px;
  color: #fff;
  font-weight: 300;
}
.colors {
  height: 60px;
  margin-top: 5px;
  border: 1px solid #353948;
  border-radius: 10px;
  padding-top: 10px;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.color {
  border: 1px solid #353948;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
