.customSelect {
  width: 100%;
  outline: none;
  height: 40px;
  border-radius: 5px;
  background-color: #212b36;
  color: white;
  border: 1px solid #353948;
  font-size: 20px;
}

.select-label {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
}
