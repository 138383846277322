body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* animeFonts start */
@font-face {
  font-family: AChildishWonders;
  src: url(./assets/fonts/animeFonts/a-childish-wonders-font/AChildishWonders-3yY3.ttf);
}
@font-face {
  font-family: AttackOnCourier;
  src: url(./assets/fonts/animeFonts/attack-on-courier-font/AttackOnCourier-5nJz.ttf);
}
@font-face {
  font-family: AvantstileRegular;
  src: url(./assets/fonts/animeFonts/avantstile-font/AvantstileRegular-ELlg.ttf);
}
@font-face {
  font-family: Chirurgeon;
  src: url(./assets/fonts/animeFonts/chirurgeon-font/Chirurgeon-DP8D.ttf);
}
@font-face {
  font-family: Doreking;
  src: url(./assets/fonts/animeFonts/doreking-font/Doreking-3BYZ.ttf);
}
@font-face {
  font-family: EllymonyBlueMsmm;
  src: url(./assets/fonts/animeFonts/ellymony-blue-msmm-font/EllymonyBlueMsmm-RxRV.ttf);
}
@font-face {
  font-family: Futurepolis3000;
  src: url(./assets/fonts/animeFonts/futurepolis-3000-font/Futurepolis3000-maY2.ttf);
}
@font-face {
  font-family: GbshintoRegular;
  src: url(./assets/fonts/animeFonts/gb-shinto-font/GbshintoRegular-VMGx.otf);
}
@font-face {
  font-family: GirlOnAnAlleyChmc;
  src: url(./assets/fonts/animeFonts/girl-on-an-alley-chmc-font/GirlOnAnAlleyChmc-W7rV.ttf);
}
@font-face {
  font-family: Hangyaku;
  src: url(./assets/fonts/animeFonts/hangyaku-font/Hangyaku-ywq23.ttf);
}
@font-face {
  font-family: HirakatanadpJX;
  src: url(./assets/fonts/animeFonts/hirakatana-font/Hirakatana-dpJX.ttf);
}
@font-face {
  font-family: KidpixiesNew;
  src: url(./assets/fonts/animeFonts/kidpixies-new-font/KidpixiesNew-ggP5.ttf);
}
@font-face {
  font-family: Lollipoptron;
  src: url(./assets/fonts/animeFonts/lollipoptron-font/Lollipoptron-7GPD.ttf);
}
@font-face {
  font-family: Monopixies2w43;
  src: url(./assets/fonts/animeFonts/monopixies-font/Monopixies-2w43.ttf);
}
@font-face {
  font-family: MsmmBetSeteWDO;
  src: url(./assets/fonts/animeFonts/msmm-bet-set-font/MsmmBetSet-eWDO.ttf);
}
@font-face {
  font-family: NightmareOrphanage;
  src: url(./assets/fonts/animeFonts/nightmare-orphanage-font/NightmareOrphanage-qnm0.ttf);
}
@font-face {
  font-family: NinjaNaruto;
  src: url(./assets/fonts/animeFonts/ninja-naruto-font/NinjaNaruto-YOn4.ttf);
}
@font-face {
  font-family: OnePiecezAqL;
  src: url(./assets/fonts/animeFonts/one-piece-font/OnePiece-zAqL.ttf);
}
@font-face {
  font-family: PokemonSolidNormal;
  src: url(./assets/fonts/animeFonts/pokemon-solid-font/PokemonSolidNormal-xyWR.ttf);
}
@font-face {
  font-family: PolandCannedIntoFuture;
  src: url(./assets/fonts/animeFonts/poland-canned-into-space-font/PolandCannedIntoFuture-OxE3.ttf);
}
@font-face {
  font-family: RaspberryMiltownRegular;
  src: url(./assets/fonts/animeFonts/raspberry-miltown-font/RaspberryMiltownRegular-8meg.ttf);
}
@font-face {
  font-family: RavenSansNbp;
  src: url(./assets/fonts/animeFonts/raven-sans-nbp-font/RavenSansNbp-6L31.ttf);
}
@font-face {
  font-family: RezaZulmiAlfaizi;
  src: url(./assets/fonts/animeFonts/reza-zulmi-alfaizi-font/RezaZulmiAlfaizi-95w7.ttf);
}
@font-face {
  font-family: RobopixiesNew;
  src: url(./assets/fonts/animeFonts/robopixies-new-font/RobopixiesNew-0nqz.ttf);
}
@font-face {
  font-family: SheStoleThePleasure;
  src: url(./assets/fonts/animeFonts/she-stole-the-pleasure-font/SheStoleThePleasure-1yyj.ttf);
}
@font-face {
  font-family: SkyrimouskiRegular;
  src: url(./assets/fonts/animeFonts/skyrimouski-font/SkyrimouskiRegular-yrjY.ttf);
}
@font-face {
  font-family: Sofiebrushzt7ZLR;
  src: url(./assets/fonts/animeFonts/sofiebrushzt-font/Sofiebrushzt-7ZLR.ttf);
}
@font-face {
  font-family: StorkTrekRegular;
  src: url(./assets/fonts/animeFonts/stork-trek-font/StorkTrekRegular-9aJn.ttf);
}
@font-face {
  font-family: Tokyosofty1qZ;
  src: url(./assets/fonts/animeFonts/tokyosoft-font/Tokyosoft-y1qZ.ttf);
}
@font-face {
  font-family: VerminorikoAki;
  src: url(./assets/fonts/animeFonts/verminoriko-aki-font/VerminorikoAki-YdGj.ttf);
}
/* 
let animeFonts = [
  "AChildishWonders",
  "AttackOnCourier",
  "AvantstileRegular",
  "Chirurgeon",
  "Doreking",
  "EllymonyBlueMsmm",
  "Futurepolis3000",
  "GbshintoRegular",
  "GirlOnAnAlleyChmc",
  "Hangyaku",
  "HirakatanadpJX",
  "KidpixiesNew",
  "Lollipoptron",
  "Monopixies2w43",
  "MsmmBetSeteWDO",
  "NightmareOrphanage",
  "NinjaNaruto",
  "OnePiecezAqL",
  "PokemonSolidNormal",
  "PolandCannedIntoFuture",
  "RaspberryMiltownRegular",
  "RavenSansNbp",
  "RezaZulmiAlfaizi",
  "RobopixiesNew",
  "SheStoleThePleasure",
  "SkyrimouskiRegular",
  "Sofiebrushzt7ZLR",
  "StorkTrekRegular",
  "Tokyosofty1qZ",
  "VerminorikoAki",   
  ];
*/
/* animeFonts end */

/* childrenFonts start */
@font-face {
  font-family: AnakCute513pv;
  src: url(./assets/fonts/childrenFonts/a-anak-cute-font/AnakCute-513pv.ttf);
}
@font-face {
  font-family: ArbeiBerry;
  src: url(./assets/fonts/childrenFonts/a-arbei-berry-font/ArbeiBerry-rg3Ky.ttf);
}
@font-face {
  font-family: AckiPreschool;
  src: url(./assets/fonts/childrenFonts/acki-preschool-font/AckiPreschool-JW5B.ttf);
}
@font-face {
  font-family: AmaliqZ3WV;
  src: url(./assets/fonts/childrenFonts/amali-font/Amali-qZ3WV.ttf);
}
@font-face {
  font-family: Artooh;
  src: url(./assets/fonts/childrenFonts/artooh-font/Artooh-qm72.ttf);
}
@font-face {
  font-family: BlubariRegular;
  src: url(./assets/fonts/childrenFonts/blubari-font/BlubariRegular-x34K5.ttf);
}
@font-face {
  font-family: BodrumJzRm;
  src: url(./assets/fonts/childrenFonts/bodrum-font/Bodrum-JzRm.ttf);
}
@font-face {
  font-family: CamdenRegular;
  src: url(./assets/fonts/childrenFonts/camden-font/CamdenRegular-1jAZ.ttf);
}
@font-face {
  font-family: CandysRGrozzRegular;
  src: url(./assets/fonts/childrenFonts/candys-r-grozz-font/CandysRGrozzRegular-zVBa.ttf);
}
@font-face {
  font-family: ClaudaergAEO;
  src: url(./assets/fonts/childrenFonts/c-claudae-font/Claudae-rgAEO.otf);
}
@font-face {
  font-family: ChildrenOnceWhere;
  src: url(./assets/fonts/childrenFonts/children-once-where-font/ChildrenOnceWhere-PV0x.ttf);
}
@font-face {
  font-family: Clemira;
  src: url(./assets/fonts/childrenFonts/clemira-font/Clemira-rgryK.otf);
}
@font-face {
  font-family: Cof;
  src: url(./assets/fonts/childrenFonts/cof-font/Cof-vngZ.ttf);
}
@font-face {
  font-family: CuteAuroraDisplay;
  src: url(./assets/fonts/childrenFonts/cute-aurora-display-font/CuteAuroraDisplay-K7WwZ.ttf);
}
@font-face {
  font-family: CuteAurora;
  src: url(./assets/fonts/childrenFonts/cute-aurora-font/CuteAurora-PK3lZ.ttf);
}
@font-face {
  font-family: Elisfirstfont;
  src: url(./assets/fonts/childrenFonts/elis-first-font/Elisfirstfont-qqnx.ttf);
}
@font-face {
  font-family: Elissecondfont;
  src: url(./assets/fonts/childrenFonts/elis-second-font/Elissecondfont-VJR0.ttf);
}
@font-face {
  font-family: FairyPlayground;
  src: url(./assets/fonts/childrenFonts/fairy-playground-font/FairyPlayground-8MeWA.ttf);
}
@font-face {
  font-family: FilthySunshine;
  src: url(./assets/fonts/childrenFonts/filthy-sunshine-font/FilthySunshine-d54V.ttf);
}
@font-face {
  font-family: Gosrokanexxl;
  src: url(./assets/fonts/childrenFonts/gosrokan-font/Gosrokan-exxl.otf);
}
@font-face {
  font-family: HennyPenny;
  src: url(./assets/fonts/childrenFonts/henny-penny-font/HennyPenny-Kp0A.ttf);
}
@font-face {
  font-family: JeanSunHoVEol;
  src: url(./assets/fonts/childrenFonts/jean-sun-ho-font/JeanSunHo-VEol.ttf);
}
@font-face {
  font-family: JlrBaby8adZ;
  src: url(./assets/fonts/childrenFonts/jlr-baby-font/JlrBaby-8adZ.ttf);
}
@font-face {
  font-family: Joker3zl9X;
  src: url(./assets/fonts/childrenFonts/joker-font/Joker-3zl9X.ttf);
}
@font-face {
  font-family: JsKang0GRG;
  src: url(./assets/fonts/childrenFonts/js-kang-font/JsKang-0GRG.ttf);
}
@font-face {
  font-family: KidsZoneBold;
  src: url(./assets/fonts/childrenFonts/kids-zone-font/KidsZoneBold-7BOpD.otf);
}
@font-face {
  font-family: Kidzone;
  src: url(./assets/fonts/childrenFonts/kidzone-font/Kidzone-2qVv.ttf);
}
@font-face {
  font-family: Kintergarden;
  src: url(./assets/fonts/childrenFonts/kintergarden-font/Kintergarden-8M8ED.ttf);
}
@font-face {
  font-family: LegoBlocks;
  src: url(./assets/fonts/childrenFonts/lego-blocks-font/LegoBlocks-9G77.ttf);
}
@font-face {
  font-family: MbakMbik1a00m;
  src: url(./assets/fonts/childrenFonts/mbak-mbik-1-font/MbakMbik1-a00m.otf);
}
@font-face {
  font-family: Mclaren99XL;
  src: url(./assets/fonts/childrenFonts/mclaren-font/Mclaren-99XL.ttf);
}
@font-face {
  font-family: MelchiorHandwrittenMedium;
  src: url(./assets/fonts/childrenFonts/melchior-handwritten-font/MelchiorHandwrittenMedium-KR7p.ttf);
}
@font-face {
  font-family: MessyHandwrittenRegular;
  src: url(./assets/fonts/childrenFonts/messy-handwritten-font/MessyHandwrittenRegular-2Onyo.ttf);
}
@font-face {
  font-family: Mory0W14o;
  src: url(./assets/fonts/childrenFonts/mory-font/Mory-0W14o.ttf);
}
@font-face {
  font-family: MyFirstFontEver;
  src: url(./assets/fonts/childrenFonts/my-first-font-ever/MyFirstFontEver-lYVy.ttf);
}
@font-face {
  font-family: MyLeftFont71vR;
  src: url(./assets/fonts/childrenFonts/my-left-font/MyLeftFont-71vR.ttf);
}
@font-face {
  font-family: PaintLettersGreekExtended;
  src: url(./assets/fonts/childrenFonts/paint-letters-font/PaintLettersGreekExtended-MWwP.ttf);
}
@font-face {
  font-family: Pequenita;
  src: url(./assets/fonts/childrenFonts/pequenita-font/Pequenita-Dy7m.ttf);
}
@font-face {
  font-family: PupilCaligraphic;
  src: url(./assets/fonts/childrenFonts/pupil-caligraphic-font/PupilCaligraphic-m5Bx.ttf);
}
@font-face {
  font-family: ReallyFreeALwl7;
  src: url(./assets/fonts/childrenFonts/really-font/ReallyFree-ALwl7.ttf);
}
@font-face {
  font-family: RestaDisplayFont;
  src: url(./assets/fonts/childrenFonts/resta-display-font/RestaDisplayFont-p7o2Z.ttf);
}
@font-face {
  font-family: Rudiment0gwP;
  src: url(./assets/fonts/childrenFonts/rudiment-font/Rudiment-0gwP.ttf);
}
@font-face {
  font-family: Schoolbell;
  src: url(./assets/fonts/childrenFonts/schoolbell-font/Schoolbell-pMMy.ttf);
}
@font-face {
  font-family: SiKancilWZdn;
  src: url(./assets/fonts/childrenFonts/si-kancil-font/SiKancil-WZdn.ttf);
}
@font-face {
  font-family: SusahuGOYnD;
  src: url(./assets/fonts/childrenFonts/susahu-font/Susahu-GOYnD.ttf);
}
@font-face {
  font-family: SyifanaK70Ky;
  src: url(./assets/fonts/childrenFonts/syifana-font/Syifana-K70Ky.ttf);
}
@font-face {
  font-family: TommysFirstAlphabet;
  src: url(./assets/fonts/childrenFonts/tommys-first-alphabet-font/TommysFirstAlphabet-axRa.ttf);
}
@font-face {
  font-family: TwoTurtleDoves;
  src: url(./assets/fonts/childrenFonts/two-turtle-doves-font/TwoTurtleDoves-ZV3q.ttf);
}
@font-face {
  font-family: WatatsukiTechSans;
  src: url(./assets/fonts/childrenFonts/watatsuki-tech-sans-font/WatatsukiTechSans-GOJxA.ttf);
}
@font-face {
  font-family: YakumoPreschoolHand;
  src: url(./assets/fonts/childrenFonts/yakumo-preschool-hand-font/YakumoPreschoolHand-4myW.ttf);
}

/* 
let childrenFonts = [
  "AnakCute513pv",
  "ArbeiBerry",
  "AckiPreschool",
  "AmaliqZ3WV",
  "Artooh",
  "BlubariRegular",
  "BodrumJzRm",
  "CamdenRegular",
  "CandysRGrozzRegular",
  "ClaudaergAEO",
  "ChildrenOnceWhere",
  "Clemira",
  "Cof",
  "CuteAuroraDisplay",
  "CuteAurora",
  "Elisfirstfont",
  "Elissecondfont",
  "FairyPlayground",
  "FilthySunshine",
  "Gosrokanexxl",
  "HennyPenny",
  "JeanSunHoVEol",
  "JlrBaby8adZ",
  "Joker3zl9X",
  "JsKang0GRG",  
  "KidsZoneBold",
  "Kidzone",
  "Kintergarden",
  "LegoBlocks",
  "MbakMbik1a00m",
  "Mclaren99XL",
  "MelchiorHandwrittenMedium",
  "MessyHandwrittenRegular",
  "Mory0W14o",
  "MyFirstFontEver",
  "MyLeftFont71vR",
  "PaintLettersGreekExtended",
  "Pequenita",
  "PupilCaligraphic",
  "ReallyFreeALwl7",
  "RestaDisplayFont",
  "Rudiment0gwP",
  "Schoolbell",
  "SiKancilWZdn",
  "SusahuGOYnD",
  "SyifanaK70Ky",
  "TommysFirstAlphabet",
  "TwoTurtleDoves",
  "WatatsukiTechSans",
  "YakumoPreschoolHand", 
];
*/
/* childrenFonts end */

/* normalFonts start */
@font-face {
  font-family: Abstractus;
  src: url(./assets/fonts/normalFonts/abstractus-font/Abstractus-1GRgB.ttf);
}
@font-face {
  font-family: AmadeusRegular;
  src: url(./assets/fonts/normalFonts/amadeus-regular-font/AmadeusRegular-VGd0V.ttf);
}
@font-face {
  font-family: ArcaneNine;
  src: url(./assets/fonts/normalFonts/arcane-nine-font/ArcaneNine-eZP1x.ttf);
}
@font-face {
  font-family: Arrosex3JPK;
  src: url(./assets/fonts/normalFonts/arrose-font/Arrose-x3JPK.ttf);
}
@font-face {
  font-family: BinjaynR5D0;
  src: url(./assets/fonts/normalFonts/binjay-font/Binjay-nR5D0.ttf);
}
@font-face {
  font-family: BlazmaX3eVP;
  src: url(./assets/fonts/normalFonts/blazma-font/Blazma-X3eVP.ttf);
}
@font-face {
  font-family: BodoAmatBWnd8;
  src: url(./assets/fonts/normalFonts/bodo-amat-font/BodoAmat-BWnd8.ttf);
}
@font-face {
  font-family: ConsulateConsulateRegular;
  src: url(./assets/fonts/normalFonts/consulate-font/ConsulateConsulateRegular-p7eZd.ttf);
}
@font-face {
  font-family: DatcubeZO2g;
  src: url(./assets/fonts/normalFonts/datcub-font/Datcub-eZO2g.ttf);
}
@font-face {
  font-family: DatdotK7prl;
  src: url(./assets/fonts/normalFonts/dat-dot-font/Datdot-K7prl.ttf);
}
@font-face {
  font-family: DonikaYzBGo;
  src: url(./assets/fonts/normalFonts/donika-font/Donika-YzBGo.ttf);
}
@font-face {
  font-family: EasyPrintRegular;
  src: url(./assets/fonts/normalFonts/easy-print-font/EasyPrintRegular-vmeRD.ttf);
}
@font-face {
  font-family: Elizabeth8Mqy0;
  src: url(./assets/fonts/normalFonts/elizabeth-font/Elizabeth-8Mqy0.ttf);
}
@font-face {
  font-family: GihunDORdR;
  src: url(./assets/fonts/normalFonts/gihun-font/Gihun-DORdR.ttf);
}
@font-face {
  font-family: HelpMeowODB;
  src: url(./assets/fonts/normalFonts/help-me-font/HelpMe-owODB.ttf);
}
@font-face {
  font-family: HeyHatersGOY5g;
  src: url(./assets/fonts/normalFonts/hey-haters-font/HeyHaters-GOY5g.ttf);
}
@font-face {
  font-family: Jengkolz8e74;
  src: url(./assets/fonts/normalFonts/jengkol-font/Jengkol-z8e74.ttf);
}
@font-face {
  font-family: Justink0Wql4;
  src: url(./assets/fonts/normalFonts/justink-font/Justink-0Wql4.ttf);
}
@font-face {
  font-family: KurlandOVKaA;
  src: url(./assets/fonts/normalFonts/kurland-font/Kurland-OVKaA.ttf);
}
@font-face {
  font-family: LikeSnow6YG8A;
  src: url(./assets/fonts/normalFonts/like-snow-font/LikeSnow-6YG8A.ttf);
}
@font-face {
  font-family: LineramaPKL3x;
  src: url(./assets/fonts/normalFonts/linerama-font/Linerama-PKL3x.ttf);
}
@font-face {
  font-family: MbkaosEaBP8;
  src: url(./assets/fonts/normalFonts/mbkaos-font/Mbkaos-EaBP8.ttf);
}
@font-face {
  font-family: NeonSansL3GXE;
  src: url(./assets/fonts/normalFonts/neon-sans-font/NeonSans-L3GXE.ttf);
}
@font-face {
  font-family: NightHalloween;
  src: url(./assets/fonts/normalFonts/night-halloween-font/NightHalloween-RpeAM.otf);
}
@font-face {
  font-family: OmniMasthead;
  src: url(./assets/fonts/normalFonts/omni-masthead-font/OmniMasthead-MVwLx.ttf);
}
@font-face {
  font-family: PartyConfettiRegular;
  src: url(./assets/fonts/normalFonts/party-confetti-font/PartyConfettiRegular-eZOn3.ttf);
}
@font-face {
  font-family: PinzelanvmGwD;
  src: url(./assets/fonts/normalFonts/pinzelan-font/Pinzelan-vmGwD.ttf);
}
@font-face {
  font-family: PixeloidMono;
  src: url(./assets/fonts/normalFonts/pixeloid-font/PixeloidMono-1G8ae.ttf);
}
@font-face {
  font-family: Quinquefive;
  src: url(./assets/fonts/normalFonts/quinque-five-font/Quinquefive-Ea6d4.ttf);
}
@font-face {
  font-family: RosetypeaxOM5;
  src: url(./assets/fonts/normalFonts/rosetype-font/Rosetype-axOM5.ttf);
}
@font-face {
  font-family: RushBerryJRvVn;
  src: url(./assets/fonts/normalFonts/rush-berry-font/RushBerry-JRvVn.ttf);
}
@font-face {
  font-family: SangGuruOVmgP;
  src: url(./assets/fonts/normalFonts/sang-guru-font/SangGuru-OVmgP.ttf);
}
@font-face {
  font-family: SmallburglgO05;
  src: url(./assets/fonts/normalFonts/smallburg-font/Smallburg-lgO05.ttf);
}
@font-face {
  font-family: SolidSoldier1G87M;
  src: url(./assets/fonts/normalFonts/solid-soldier-font/SolidSoldier-1G87M.ttf);
}
@font-face {
  font-family: SusahuGOYnD;
  src: url(./assets/fonts/normalFonts/susahu-font/Susahu-GOYnD.ttf);
}
@font-face {
  font-family: ThinPrintRegular;
  src: url(./assets/fonts/normalFonts/thin-print-font/ThinPrintRegular-ALl4A.ttf);
}
@font-face {
  font-family: WartegZV8qz;
  src: url(./assets/fonts/normalFonts/warteg-font/Warteg-ZV8qz.ttf);
}
@font-face {
  font-family: XTypewriter;
  src: url(./assets/fonts/normalFonts/x-typewriter-font/XTypewriter-7BKJB.ttf);
}
@font-face {
  font-family: Zantung2OYg3;
  src: url(./assets/fonts/normalFonts/zantung-font/Zantung-2OYg3.ttf);
}
@font-face {
  font-family: Zector3zxAZ;
  src: url(./assets/fonts/normalFonts/zector-font/Zector-3zxAZ.ttf);
}
/* 
  let normalFonts = [
    "Abstractus", 
    "AmadeusRegular", 
    "ArcaneNine",
    "Arrosex3JPK",
    "BinjaynR5D0",
    "BlazmaX3eVP",    
    "BodoAmatBWnd8",    
    "ConsulateConsulateRegular",
    "DatcubeZO2g",
    "DatdotK7prl",
    "DonikaYzBGo",
    "EasyPrintRegular",
    "Elizabeth8Mqy0",
    "GihunDORdR",
    "HelpMeowODB",
    "HeyHatersGOY5g",
    "Jengkolz8e74",
    "Justink0Wql4",
    "KurlandOVKaA",
    "LikeSnow6YG8A",
    "LineramaPKL3x",
    "MbkaosEaBP8",
    "NeonSansL3GXE",
    "NightHalloween",
    "OmniMasthead",
    "PartyConfettiRegular",
    "PinzelanvmGwD",
    "PixeloidMono",
    "Quinquefive",
    "RosetypeaxOM5",
    "RushBerryJRvVn",
    "SangGuruOVmgP",
    "SmallburglgO05",
    "SolidSoldier1G87M",
    "SusahuGOYnD",
    "ThinPrintRegular",
    "WartegZV8qz",
    "XTypewriter",
    "Zantung2OYg3",
    "Zector3zxAZ",    
  ];
*/
/* normalFonts end */
