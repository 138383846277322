.input-fileld {
  margin-bottom: 10px;
  display: flex;
}

.input-fileld .left {
  width: 200px;
}
.canv-lable {
  color: #d6e4ff;
}
.custom-input {
  outline: none;
  border: 1px solid #1ccaff;
  background-color: transparent;
  padding: 10px;
  font-size: 14px;
  width: 230px;
  color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
